<template>
    <painel titulo="RDStation - Parâmetros" icone="pi pi-cog" :refreshFunction="obterParametros" v-if="mostrarDetalhes">
        <div class="mb-4">
            <Message severity="info" :closable="false">Informar o caminho completo da propriedade separando as partes por "|"</Message>
            <detalhe titulo="Identificador" :size="size">
                {{ parametros?.identificador }}
            </detalhe>
            <detalhe titulo="Descrição do Atendimento" :size="size">
                {{ parametros?.descricaoAtendimento }}
            </detalhe>
            <detalhe titulo="Data do Atendimento" :size="size">
                {{ parametros?.dataAtendimento }}
            </detalhe>
            <detalhe titulo="CPF" :size="size">
                {{ parametros?.cpf }}
            </detalhe>
            <detalhe titulo="Nome PF" :size="size">
                {{ parametros?.nomePF }}
            </detalhe>
            <detalhe titulo="CNPJ" :size="size">
                {{ parametros?.cnpj }}
            </detalhe>
            <detalhe titulo="Email" :size="size">
                {{ parametros?.email }}
            </detalhe>
            <detalhe titulo="Telefone" :size="size">
                {{ parametros?.telefone }}
            </detalhe>
            <detalhe titulo="Cidade" :size="size">
                {{ parametros?.cidade }}
            </detalhe>
            <detalhe titulo="UF" :size="size">
                {{ parametros?.uf }}
            </detalhe>
        </div>
        <Button label="Atualizar" icon="pi pi-pencil" @click="toAtualizar()" v-if="$temAcessoView('RD-PARAMETROS-01')" />
    </painel>
    <router-view :parametros="parametros" @obterParametrosAtualizados="obterParametros()"></router-view>
</template>

<script>
import Services from './services';

export default {
    components: {},

    data() {
        return {
            parametros: null,
            size: '180',
        };
    },

    methods: {
        obterParametros() {
            this.$store.dispatch('addRequest');
            Services.obterParametros().then((response) => {
                if (response?.success) {
                    this.parametros = response.data;
                } else {
                    this.parametros = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toAtualizar() {
            this.$router.push({
                name: 'RdStation_Parametros_Atualizar',
            });
        },
    },

    computed: {
        mostrarDetalhes() {
            return this.$route.name == 'RdStation_Parametros';
        },
    },

    mounted() {
        this.obterParametros();
    },
};
</script>
